import op1 from "../../components/assets/Opinion/img1.jpeg"
import op2 from "../../components/assets/Opinion/img2.jpeg"
import op3 from "../../components/assets/Opinion/img3.jpeg"
import op4 from "../../components/assets/Opinion/rio.jpg"
import op5 from "../../components/assets/Opinion/ASFIXIADERO.png"
import op6 from "../../components/assets/Opinion/indig.png"
import op7 from "../../components/assets/Opinion/denunciaobras.png"
import op8 from "../../components/assets/Opinion/paseo.png"
import op9 from "../../components/assets/Opinion/edificios.png"
import op10 from "../../components/assets/Opinion/Copia de White.png"
/*

instrucciones:
si desea agreagar texto, se introduce dentro de las comillas `texto a introducir`
*/

function arregloO(){
  const moment = require("moment")

  let array = [
    { fechas: "24-2",
      imagen: op1, 
      titulo: `EL MEJOR VIVIDERO DEL ÁREA METROPOLITANA`,

      text: `Sabaneta es un municipio que cautiva a primera vista; un lugar muy “titino”, como dirían los paisas.
      Su encanto nace de la particular combinación entre pueblo y ciudad, con lo mejor de uno y otro.
      Con 15 kilómetros cuadrados de extensión es el municipio más pequeño de Colombia.`,

      textomas:`No obstante, las opciones para pasarla bien y acceder a “todos los juguetes” de una gran ciudad son numerosas.
      Así, el municipio ha propiciado una buena parte de la venta de viviendas nuevas en el sur del Valle de Aburrá.
      Muchas familias han optado por asentarse en la localidad y vivir rodeadas de montañas y naturaleza.
      Exóticas zonas veredales e incluso áreas de reserva forestal se distribuyen con el área urbana. A Sabaneta solo la separa de Medellín el municipio de Envigado.
      Por eso, desplazarse hacia la capital antioqueña toma unos 15 minutos en carro.
      El municipio tiene acceso a importantes vías regionales que cruzan de sur a norte gran parte del área metropolitana.
      Puedes llegar y salir a través de la avenida Las Vegas, El Poblado o la Avenida Regional. Publicado por: Misabaneta.com`,

    },
    
    
    { fechas: "25-2",
      imagen: op2, 
      titulo: `BIBLIOTECA PÚBLICA MUNICIPAL`,
      text: `
      Las bibliotecas son una de las instituciones más valiosas de nuestra sociedad.
      Desde tiempos antiguos, han sido un lugar para el aprendizaje, la investigación y la exploración.
      Hoy en día, las bibliotecas desempeñan un papel crucial en la educación y en la promoción de la alfabetización y el conocimiento.
      `,
      textomas:`
      Son importantes porque proporcionan acceso a una amplia variedad de recursos de información.
      Las bibliotecas no solo tienen libros, sino también revistas, periódicos, documentos gubernamentales, mapas y archivos de audio y video.
      Estos recursos son valiosos para investigadores, estudiantes y cualquier persona que esté interesada en aprender y ampliar sus conocimientos.
      Además, las bibliotecas ofrecen servicios de referencia y asistencia a los usuarios.
      Los bibliotecarios están capacitados para ayudar a las personas a encontrar la información que necesitan y a navegar por los recursos de la biblioteca.
      Los bibliotecarios también pueden ayudar a las personas a desarrollar habilidades de investigación y a evaluar la calidad de la información que encuentran en línea.
      Otra razón por la cual las bibliotecas son importantes es porque son un lugar seguro y accesible para la comunidad. Las bibliotecas son lugares donde las personas pueden reunirse y conectarse en un ambiente seguro y sin prejuicios.
      También son espacios para estudiar, trabajar y relajarse, y también organizan eventos y programas para la comunidad.
      En resumen, las bibliotecas son una parte fundamental de nuestra sociedad. Proporcionan acceso a una amplia variedad de recursos de información,
      ofrecen servicios de referencia y asistencia a los usuarios, fomentan la alfabetización y el amor por la lectura, y son un lugar seguro y accesible para la comunidad. Es importante que sigamos apoyando y promoviendo nuestras bibliotecas públicas para que puedan seguir desempeñando su papel vital en la educación y el conocimiento de nuestra sociedad.
      ¿Qué tenemos sin bibliotecas? No tenemos pasado y no tenemos futuro”RayBradbury.
      `,

    },

    { fechas: "26-2",
      imagen: op3, 
      titulo: `EL PROBLEMA DE LAS BASURAS EN SABANETA:        
      Una situación que nos concierne a todos`,
      text: `Uno de los principales problemas que enfrentamos en nuestro municipio es la acumulación de basuras en áreas públicas y calles.
      En el barrio Villas del Carmen por ejemplo, muchos vecinos sienten esta problemática al ver acumuladas las basuras en las esquinas , sin respetar los horarios de recolección de basura o el adecuado uso de los contenedores dispuestos para tal fin.
      Esto ha generado la formación de vertederos improvisados, que además de ser antiestéticos, emiten olores desagradables y atraen plagas de insectos y roedores.
      Esto representa un riesgo para la salud de los habitantes del barrio, así como una imagen negativa para nuestra comunidad.`,
      textomas:`Desde Misabaneta.com queremos hacer un llamado a las autoridades locales a que pongan el ojo en esta problemática, pero también hacemos un llamado a la comunidad ya que es un tema que nos concierne a todos.
      Es responsabilidad de cada uno de nosotros adoptar prácticas adecuadas de manejo de los residuos sólidos, como separarlos correctamente en nuestras casas, respetar los horarios de recolección y hacer uso adecuado de los contenedores dispuestos para tal fin.
      También es fundamental promover la concientización y educación sobre la importancia del reciclaje y la reducción de la generación de basuras. Es necesario que trabajemos juntos como comunidad, en coordinación con las autoridades locales y otros actores relevantes, para implementar soluciones efectivas que aborden esta problemática.
      Publicado por: Misabaneta.com `,

    },
    

    
    { fechas: "28-1",
      imagen: op4, 
      titulo: `DENUNCIA CIUDADANA `,
      text: `Así se veía la quebrada La Honda o Palenque el 17 de Abril. Desde la ciudadanía se han interpuesto quejas
      de esta situación también para las quebradas la Sabanetica y las Lomitas. Pero la autoridad competente parece hacer
      caso omiso a estas denuncias. Desde Misabaneta.com hacemos un llamado a la preservación de nuestras fuentes hídricas,
      de riachuelos, quebradas y ríos que atraviesan nuestro municipio, para que no se conviertan en vertederos de desechos.`,
      textomas:`Recordemos que es el habitat de cientos de especies 🙌🐿🐾🐇🐢🐍🦎 Publicado por: Misabaneta.com`,

    }, 
    { fechas: "28-2",
      imagen: op5, 
      titulo: `INDIGNACIÓN CIUDADANA `,
      text: `Un ciudadano nos envía su preocupación al ver que en la loma El Asfixiadero, lugar tranquilo e ideal para hacer deporte. Al pie de uno de los lotes de cafetales más grandes que tenemos en el municipio, se esta construyendo una parcelación, que sin descaro tienen como argumento de venta que es en una hermosa reserva natural. 🤦‍♀️

      Más allá de la legalidad de la licencia, se cuestiona sobre el carácter moral que decide convertir en ladrillo un respiradero natural y un ecosistema digno de ser preservado.
      ¿Usted qué opina?.`,
      textomas:`Recordemos que es el habitat de cientos de especies 🙌🐿🐾🐇🐢🐍🦎 Publicado por: Misabaneta.com`,

    }, 
    { fechas: "29-3",
    imagen: op6, 
    titulo: `       
    YA NI HAY SOL EN LOS APARTAMENTOS DE SABANETA`,
    text: `En un municipio donde la mala planificación urbana ha dejado su marca, nos encontramos con un laberinto de edificaciones, una encima de la otra.  Espacios públicos desatendidos y una falta alarmante de infraestructura adecuada.
    Este crecimiento desmedido ha generado una carencia de espacios verdes y áreas recreativas. Sumado al caos vehicular. Es evidente que la falta de visión y la negligencia han llevado a un desarrollo caótico y desorganizado.
    ¿Hasta cuando seguiremos permitiendo esto en el vallecito del encanto?`,
    textomas:`Desde Misabaneta.com queremos hacer un llamado a las autoridades locales a que pongan el ojo en esta problemática. El mensaje tambièn esta dirigido a los candidatos a la alcaldìa para que puedan interpretar estas circunstancias e incluyan en su plan de gobierno una solución para esto.
    Publicado por: Misabaneta.com `,

  },
  { fechas: "28-4",
      imagen: op7, 
      titulo: `El daño de las obras incompletas en favoritismo político: Un atentado contra el bienestar ciudadano `,
      text: `En el contexto político, es fundamental que los alcaldes y las autoridades municipales cumplan con su deber de gestionar y entregar obras públicas completas y de calidad que beneficien a la comunidad. Sin embargo, cuando un alcalde prioriza su preferencia política sobre el bienestar ciudadano, y entrega obras incompletas con el propósito de favorecer a un candidato específico en las elecciones municipales, se genera un daño significativo que afecta tanto a la infraestructura como al tejido social de una localidad.
      Así como sucedió con la entrega prematura del parquesito de Entreamigos, parece ser la entrega de la Central de Monitoreo en la que se evidencia la instalación de palos pero sin las cámaras. Lo más preocupante es el alto costo de los mismos y que la entrega por parte de la Alcaldía es sin una debida socialización.

      
      ¿Usted qué opina?.`,
      textomas:`Las obras son de y para la comunidad, no para inflar candidaturas. Publicado por: Misabaneta.com`,

    },
    { fechas: "28-3",
    imagen: op8, 
    titulo: `       
    LOS PASEOS MILLONARIOS DEL CONCEJO DE SABANETA`,
    text: `Increíble pero cierto. Estan paseando con la plata de todos los sabaneteños. 🙌

    Mientras que la mayoría de los sabaneteños tenemos que sudarla para darnos un paseo a la costa. Nuestros representantes en el concejo se van para Madrid y Roma a hacer un curso de tan solo 52 horas. 
    Parece casi un milagro que les concede la virgen de la iglesia de María Auxiliadora a los concejales de Sabaneta, quienes por tercera vez este año saldrán del país para capacitarse después de estar por México y Argentina.
    
    El pasado 17 de mayo el presidente del concejo Ángel Fabricio Henao firmó con la corporación Colombia Vive el contrato directo 052-2023 cuyo objeto es la realización de un curso nacional e internacional “en el fortalecimiento y mejoramiento de las competencias en movilidad sostenible, encuentros culturales y educativos con herramientas políticas y prácticas implementadas en otros países referentes para aportar y replicar nuevas ideas y estrategias que fortalezcan el progreso sostenible local”.`,
    textomas:`En la propuesta hecha por la Corporación se lee que el curso tendrá una duración de solamente 52 horas. En uno de los objetivos específicos del curso se lee que la idea es “Conocer las características culturales, sociales y económicas de Europa, como su historia, arte, gastronomía y valores culturales”.
    Fuente: El Colombiano.
    Publicado por: Misabaneta.com `,

  },

  { fechas: "28-4",
    imagen: op9, 
    titulo: `       
    QUÉ ESTA PASANDO CON LOS EDIFICIOS ABANDONADOS EN SABANETA`,
    text: `Es increíble que sigan pasando alcaldes por la administración municipal y este edificio siga acumulando moho, convirtiendose en un foco de problemas para todos los vecinos e implicados. 🏗🚫🆘.
    
    `,
    textomas:`Son varios los proyectos de vivienda que han sido abandonados a medio empezar en Sabaneta, producto de una inexistente planeación territorial que responde a un afán de los últimos alcaldes de dar licencias de construcción a diestra y siniestra sin pensar en los problemas futuros que puedan aparecer.

    Este edificio abandonado sobre la avenida las Vegas se volvió parte del paisaje, esta lleno de ratas y las estructuras se estan desprendiendo poniendo en peligro la vida de los vecinos.🐭🚧
    
    Lo peor de todo es que nadie responde. En esta época electoral hacemos un llamado a todos los candidatos a ponerle la lupa...
    SABANETA PARECE QUE VA PERDIENDO.
    
    ¿Usted qué opina?.
    Fuente: El Colombiano.
    Publicado por: Misabaneta.com `,

  },

  { fechas: "30-4",
  imagen: op10, 
  titulo: `QUIÉN VA GANANDO LA ALCALDÍA DE SABANETA...`,
  text: `En MiSabaneta.com nos dimos a la tarea de revisar las encuestas y esto fue lo que encontramos. 🙌🤯.

  
  ¿Usted qué opina?.`,
  textomas:`La encuesta realizada por la empresa (T&SE) publicada en Minuto30 el mes pasado, nos brinda un panorama de los candidatos más opcionados a ganar la Alcaldía de Sabaneta. Si bien el voto en blanco fue el gran ganador, los resultados después del voto en blanco nos pueden brindar un pronóstico de disputa entre 3 candidatos. Se trata de @danielmarquezgomez y @aldercruzoficial, dos jóvenes de ideas frescas y de experiencia en lo privado como en lo público, y del exalcalde @ivan_alonso_montoya; le sigue en la cuarta posición Daniel Galeano con 5,53%.

  Pueden encontrar la ficha técnica de dicha encuesta en: https://www.minuto30.com/daniel-marquez-alder-cruz-encuesta-sabaneta/1457874/
  
  También encontramos otros resultados de encuestas en redes sociales que favorecen al candidato Juan Carlos Bustamante.
  
  Son datos y hay que darlos...
  
  Cuéntanos en los comentarios:
  
  ¿Si las elecciones para la Alcaldía de Sabaneta fueran el próximo domingo?
  ¿Usted por quién votaría?
  
  🙌✅. Publicado por: Misabaneta.com`,

},
    
      

  ];

  const sortedArray = array.sort((a, b) => moment(b.fechas, "DD-MM").unix() - moment(a.fechas, "DD-MM").unix());
  
  return(
    
    sortedArray

  )
}

export default arregloO;
