
import oferta1 from "../../components/assets/BlogEmpleo/barbero.jpg"
import oferta3 from "../../components/assets/BlogEmpleo/varias.jpg"
import oferta4 from "../../components/assets/BlogEmpleo/conductoreme.jpg"
import oferta5 from "../../components/assets/BlogEmpleo/cocinera.jpg"
import oferta6 from "../../components/assets/BlogEmpleo/practicantediseno.jpg"
import oferta7 from "../../components/assets/BlogEmpleo/manicurista.jpg"
//import oferta8 from "../../components/assets/BlogEmpleo/empleadadomestica.jpg"
import oferta9 from "../../components/assets/BlogEmpleo/oferta.jpg"
import oferta10 from "../../components/assets/BlogEmpleo/oferta 17.jpg"
import oferta11 from "../../components/assets/BlogEmpleo/oferta 18.jpg"
import oferta12 from "../../components/assets/BlogEmpleo/oferta 19.jpg"
import oferta13 from "../../components/assets/BlogEmpleo/oferta 20.jpg"
import oferta14 from "../../components/assets/BlogEmpleo/oferta 21.jpg"
import oferta15 from "../../components/assets/BlogEmpleo/oferta 22.jpg"
import oferta16 from "../../components/assets/BlogEmpleo/oferta 30.jpg"
import oferta17 from "../../components/assets/BlogEmpleo/oferta 31.jpg"
import oferta18 from "../../components/assets/BlogEmpleo/trabajo 23.jpg"
import oferta19 from "../../components/assets/BlogEmpleo/trabajo 24.jpg"
import oferta20 from "../../components/assets/BlogEmpleo/trabajo 25.jpg"
import oferta21 from "../../components/assets/BlogEmpleo/trabajo30.jpg"
import oferta22 from "../../components/assets/BlogEmpleo/finooo.jpg"
import oferta23 from "../../components/assets/BlogEmpleo/fino2.jpg"
import oferta100 from "../../components/assets/BlogEmpleo/oferta100.jpeg"
import oferta101 from "../../components/assets/BlogEmpleo/oferta101.jpeg"
import oferta102 from "../../components/assets/BlogEmpleo/oferta102.jpeg"
import oferta103 from "../../components/assets/BlogEmpleo/oferta103.jpeg"
import oferta104 from "../../components/assets/BlogEmpleo/oferta104.jpeg"
import oferta105 from "../../components/assets/BlogEmpleo/oferta105.jpeg"
import oferta106 from "../../components/assets/BlogEmpleo/oferta106.jpeg"
import oferta33 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO33.jpeg"
import oferta34 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO34.jpeg"
import oferta35 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO 35.jpeg"
import oferta36 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO 36.jpeg"
import oferta37 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO 37.jpeg"
import oferta38 from "../../components/assets/BlogEmpleo/OFERTA TRABAJO 38.jpeg"
import oferta39 from "../../components/assets/BlogEmpleo/sept1.jpg"
import oferta40 from "../../components/assets/BlogEmpleo/sept2.png"
import oferta41 from "../../components/assets/BlogEmpleo/sept3.jpg"
import oferta42 from "../../components/assets/BlogEmpleo/sept4.jpg"
import oferta43 from "../../components/assets/BlogEmpleo/sept5.jpg"

function arreglo(){
  const moment = require("moment")

  let array = [
    { fechas: "28-04",
      imagen: oferta1, 
      
      text: "Se busca manicurista barbero y estilista en Sabaneta. Escribir al Whatsapp: 302 207 0539. Fecha publicación: 28 abril 2023"
    },

    { fechas: "24-04",
      imagen: oferta3, 
      
      text: "Encuentra diversas ofertas laborales en BYS group, visita www.bysgroup.co. Contacto: 320 494 2276. Fecha publicación: 24 abril 2023"
    },

    { fechas: "28-04",
      imagen: oferta4, 
      
      text: "Se requiere conductor para transporte de mascotas. Interesados comunicarse al: 318 782 7455.Fecha publicación: 28 abril 2023"
    },

    { fechas: "19-04",
      imagen: oferta5, 
      
      text: "Se busca cocinera en Sabaneta. Enviar hoja de vida al correo: Andress1030@hotmail.com .Fecha publicación: 19 abril 2023"
    },

    { fechas: "19-04",
      imagen: oferta6, 
      
      text: "Se busca practicante de diseño gráfico. Enviar hoja de vida al correo: juliana.ardila@ellipselingerie.com. Fecha publicación: 19 abril 2023"
    },

    { fechas: "19-04",
      imagen: oferta7, 
      
      text: "Se busca Manicurista en Sabaneta. Enviar hoja de vida al correo: centrodeesteticazacha@gmail.com .Fecha publicación: 19 abril 2023"
    },

    { fechas: "15-05",
    imagen: oferta9, 
    
    text: "Se busca Barista. Enviar hoja de vida al correo: lapanadeira1@gmail.com. Cel: 300 537 80 63. Fecha publicación: 15 mayo 2023"
  },
  { fechas: "16-05",
  imagen: oferta10, 
  
  text: "Se busca analista retención al cliente. Enviar hoja de vida al correo: eliana.torres@finoservices.com. Cel: 312 407 6429. Fecha publicación: 16 mayo 2023"
},
{ fechas: "17-05",
      imagen: oferta11, 
      
      text: "Se busca domiciliario de confianza con moto. Cel: 3225158346.Fecha publicación: 17 mayo 2023"
    },
    { fechas: "17-05",
      imagen: oferta12, 
      
      text: "Se busca manicurista. Enviar hoja de vida al correo: julianrr83@gmail.com .Fecha publicación: 17 mayo 2023"
    },
    { fechas: "17-05",
      imagen: oferta13, 
      
      text: "Se busca asesor atención al cliente. Enviar hoja de vida al correo: contactenos@conceptbpo.com.co. Fecha publicación: 17 mayo 2023"
    },
    { fechas: "17-05",
    imagen: oferta14, 
    
    text: "Se busca técnico de confección. Revisa la convocatoria en comunidad.mattelsa.net .Fecha publicación: 17 mayo 2023"
  },
  { fechas: "14-05",
      imagen: oferta15, 
      
      text: "Se busca auxiliar de bordado. Comunicate al 315 268 07 81 .Fecha publicación: 12 mayo 2023"
    },
    { fechas: "18-05",
    imagen: oferta16, 
    
    text: "Se buscan asesoras comerciales.  Enviar hoja de vida al correo: talentohumano@grupofresh.co. Fecha publicación: 17 mayo 2023"
  },
  { fechas: "18-05",
  imagen: oferta17, 
  
  text: "Se busca desarrollador de mercado.  Enviar hoja de vida al correo: talentohumano@grupofresh.co. Fecha publicación: 17 mayo 2023"
},
{ fechas: "22-05",
  imagen: oferta18, 
  
  text: "Se busca Coordinador de confección en Medellín.  Enviar hoja de vida al correo: seleccion@amatiss.com.co. Fecha publicación: 30 mayo 2023"
},
{ fechas: "22-05",
  imagen: oferta19, 
  
  text: "Se Auditor de calidad textil en Medellín.  Enviar hoja de vida al correo: seleccion@amatiss.com.co. Fecha publicación: 30 mayo 2023"
},
{ fechas: "22-05",
  imagen: oferta20, 
  
  text: "Se buscan trabajadoras para servicios de aseo en casa.  Enviar hoja de vida al correo: hojadevida.limpiezadehogar@gmail.co - Whatsapp: 3218117506. Fecha publicación: 30 mayo 2023"
},
{ fechas: "22-05",
  imagen: oferta21, 
  
  text: "Se buscan auxiliar logistico.  Enviar hoja de vida al correo: serlicad.dadd@gmail.co - Whatsapp: 3148555335. Fecha publicación: 15 junio 2023"
},
{ fechas: "25-05",
  imagen: oferta22, 
  
  text: "Se busca domiciliario. Whatsapp: 3014832890. Fecha publicación: 21 junio 2023"
},
{ fechas: "25-05",
  imagen: oferta23, 
  
  text: "Se busca personal para puesto de comidas en Sabaneta - Whatsapp: 3235865558. Fecha publicación: 20 junio 2023"
},
{ fechas: "24-05",
  imagen: oferta100, 
  
  text: "Se busca mesero con experiencia en atención al cliente. Salario mínimo más prestaciones. De 3:00 pm a 11pm - Whatsapp: 3106037047. Fecha publicación: 1 julio 2023"
},
{ fechas: "24-05",
  imagen: oferta101, 
  
  text: "Se busca auxiliar administrativo. Mínimo 1 año de experiencia. Cierre convocatoria el 3 de julio - Enviar hoja de vida al seleccion@crantioquia.org.co . Fecha publicación: 1 julio 2023"
},
{ fechas: "24-06",
  imagen: oferta102, 
  
  text: "Se busca operario de producciòn. Mínimo 6 meses de experiencia - Enviar hoja de vida al procesos@estraefectivas.com. Fecha publicación: 6 julio 2023"
},
{ fechas: "24-06",
  imagen: oferta103, 
  
  text: "Se busca personal para puesto de comidas en Sabaneta - Enviar hoja de vida al encantofrutal33@gmail.com. Fecha publicación: 6 julio 2023"
},
{ fechas: "24-06",
  imagen: oferta104, 
  
  text: "Se busca practicante de mercadeo y publicidad - Whatsapp: 3124076429. Fecha publicación: 6 julio 2023"
},
{ fechas: "24-06",
  imagen: oferta105, 
  
  text: "Se busca personal para gestión comercial - Whatsapp: 3182069068. Fecha publicación: 6 julio 2023"
},
{ fechas: "24-06",
  imagen: oferta106, 
  
  text: "Se busca personal para vendedor freelance en uniformes de dotaciòn industrial - Whatsapp: 3003276222. Fecha publicación: 6 julio 2023"
},
{ fechas: "25-06",
  imagen: oferta33, 
  
  text: "Se busca auxiliar de cocina - Whatsapp: 322 209 8366. Fecha publicación: 11 julio 2023"
},
{ fechas: "27-06",
  imagen: oferta34, 
  
  text: "Se busca estilistas, manicuristas, lashista - Whatsapp: 323 597 4124. Fecha publicación: 19 julio 2023"
},
{ fechas: "27-06",
  imagen: oferta35, 
  
  text: "Se busca asesor (a) comercial externo - Enviar hoja de vida al administrativo@incolthinner.com . Fecha publicación: 18 julio 2023"
},
{ fechas: "25-06",
  imagen: oferta36, 
  
  text: "Se busca mesero, cajero, auxiliar de servicio a la mesa y parrillero con experiencia - Whatsapp: 305 400 06 39 . Fecha publicación: 10 julio 2023"
},
{ fechas: "25-06",
  imagen: oferta37, 
  
  text: "Se busca mesero, cajero, auxiliar de servicio a la mesa y parrillero con experiencia - Whatsapp: 305 394 13 54 . Fecha publicación: 10 julio 2023"
},
{ fechas: "27-06",
  imagen: oferta38, 
  
  text: "Se buscan auxiliares de lavado - Whatsapp: 321 364 06 56 . Fecha publicación: 19 julio 2023"
},
{ fechas: "27-07",
  imagen: oferta39, 
  
  text: "Se buscan auxiliares de taller - Whatsapp: 323 2214543 . Fecha publicación: 2 septiembre 2023"
},
{ fechas: "26-07",
  imagen: oferta40, 
  
  text: "Se busca Mesera con experiencia - Whatsapp: 301 622 5827 . Fecha publicación: 1 septiembre 2023"
},
{ fechas: "26-07",
  imagen: oferta41, 
  
  text: "Se auxiliar contable - Enviar correo a elizabeth.pabon@grupoadministrativo.com . Fecha publicación: 1 septiembre 2023"
},
{ fechas: "26-07",
  imagen: oferta42, 
  
  text: "Se buscan operarios de lavado - Whatsapp: 323 2214543 . Fecha publicación: 2 septiembre 2023"
},
{ fechas: "26-07",
  imagen: oferta43, 
  
  text: "Se buscan herramenteros - Whatsapp: 323 2214543 . Fecha publicación: 2 septiembre 2023"
},


  ];

  const sortedArray = array.sort((a, b) => moment(b.fechas, "DD-MM").unix() - moment(a.fechas, "DD-MM").unix());
  
  return(
    
    sortedArray

  )
}

export default arreglo;
/*
hola = arreglo()
hola2 = hola[0]
console.log(hola[0].heading)*/