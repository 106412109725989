import "./datosStyle.css"
import Datos from "./Datos"


import arregloE from "./arregloE"

function EDatos(){
    var dato = 0;
    var empleo= arregloE();
    var empleo_fin = [];
    var lengthE = empleo.length;
    var i = -1;
    while (i < lengthE){
        if (i < 0){
            empleo_fin.push(constructorI())
            i++;
        }
        console.log(empleo.length);
        if((lengthE - dato) >= 3){
            
            empleo_fin.push(constructor(dato,empleo))
            dato += 3;
            i += 3;
        }

        if((lengthE - dato) === 2 ){
            
            empleo_fin.push(constructor2(dato,empleo))
            dato += 2;
            i += 2;
        }

        if((lengthE - dato) === 1 ){
            
            empleo_fin.push(constructor1(dato,empleo))
            dato += 1;
            i += 1;
        }

        /*
        
        if((lengthE - dato) > 1 && (lengthE - dato) < 2 ){
            
            empleo_fin.push(constructor(dato,empleo))
            dato += 3;
            i += 3;
        }
        */
        
    }

    
    return(empleo_fin)
    
}

export function constructorI(){
    return(
    <div className="datos">
    <h1>Encuentra aquí las recientes ofertas de empleo para los sabaneteños</h1>
    </div>
    )
}




export function constructor(dato, empleo){
    return(
    <div className="datos">
        <div className="Datos">
            <Datos
            image={empleo[dato].imagen}
            
            text={empleo[dato].text}
            />
            
            <Datos
            image={empleo[dato+1].imagen}
            
            text={empleo[dato+1].text}
            />
            <Datos
            image={empleo[dato+2].imagen}
            
            text={empleo[dato+2].text}
            />
        </div>
    </div>
    )
}

export function constructor2(dato, empleo){
    return(
    <div className="datos">
        <div className="Datos">
            <Datos
            image={empleo[dato].imagen}
            
            text={empleo[dato].text}
            />
            
            <Datos
            image={empleo[dato+1].imagen}
            
            text={empleo[dato+1].text}
            />
        </div>
    </div>
    )
}

export function constructor1(dato, empleo){
    return(
    <div className="datos">
        <div className="Datos">
            <Datos
            image={empleo[dato].imagen}
            
            text={empleo[dato].text}
            />
            
        </div>
    </div>
    )
}


/*
function EDatos(){
    return(
    <div className="datos">
        <h1>Encuentra aquí las recientes ofertas de empleo para los sabaneteños</h1>
        <div className="Datos">
            <Datos
            image={of1}
            
            text="Se busca manicurista en Sabaneta. Escribir al Whatsapp: 310 581 0247.
    Fecha publicación: 10 abril 2023"
            />

            <Datos
            image={of2}
            
            text="Interesados llamar al 313 759 0665.Fecha publicación: 10 abril 2023 "
            />
 <Datos
            image={of3}
            
            text="Trabajo de lunes a sábado. Envía tu hoja de vida al correo: getandshare2023@gmail.com. Fecha publicación: 10 abril 2023"
            />
            <Datos
            image={of4}
            
            text="Fecha publicación: 10 abril 2023"
            />


            
        </div>

        <div className="Datos">
            <Datos
            image={of5}
            
            text="Se busca cocinera en Sabaneta. Enviar hoja de vida al correo: Andress1030@hotmail.com
    .Fecha publicación: 19 abril 2023"
            />
        
        <Datos
            image={of6}
            
            text="Se busca practicante de diseño gráfico. Enviar hoja de vida al correo: juliana.ardila@ellipselingerie.com. Fecha publicación: 19 abril 2023 "
            />
             <Datos
            image={of7}
            
            text="Se busca Manicurista en Sabaneta. Enviar hoja de vida al correo: centrodeesteticazacha@gmail.com .Fecha publicación: 19 abril 2023 "
            />
            <Datos
            image={of8}
            
            text="Se busca empleada doméstica. Enviar hoja de vida al correo: seleccion@nannyshousekeeping.com.Fecha publicación: 19 abril 2023 "
            />
    </div>
    
    </div>

    )
    

}
*/



export default EDatos