import "./datosStyleR.css"
import Datos from "./Datos"


import PR1 from "../../components/assets/restaurantes/PR1.jpg"
import PR2 from "../../components/assets/restaurantes/PR2.jpg"
import PR3 from "../../components/assets/restaurantes/PR3.jpg"



import arregloR from "./arregloR"


function RDatos(){
    var dato = 0;
    var restaurante= arregloR();
    var restaurante_fin = [];
    var lengthE = restaurante.length;
    var i = 0;
    while (i < lengthE){
        console.log(restaurante.length);
        if((lengthE - dato) >= 3){
            
            restaurante_fin.push(constructor(dato,restaurante))
            dato += 3;
            i += 3;
        }

        if((lengthE - dato) === 2 ){
            
            restaurante_fin.push(constructor2(dato,restaurante))
            dato += 2;
            i += 2;
        }

        if((lengthE - dato) === 1 ){
            
            restaurante_fin.push(constructor1(dato,restaurante))
            dato += 1;
            i += 1;
        }
        
    }

    restaurante_fin.push(ender())

    
    return(restaurante_fin)
    
}

export function constructor(dato, restaurante){
    return(

        <div className="datosR">
        
        <div className="DatosR">
            <Datos
            image={restaurante[dato].imagen}
            heading= {restaurante[dato].titulo}
            heading2= {restaurante[dato].titulo2}
            text={restaurante[dato].text}
            />
            <Datos
            image={restaurante[dato+1].imagen}
            heading= {restaurante[dato+1].titulo}
            heading2= {restaurante[dato+1].titulo2}
            text={restaurante[dato+1].text}
            />
            <Datos
            image={restaurante[dato+2].imagen}
            heading= {restaurante[dato+2].titulo}
            heading2= {restaurante[dato+2].titulo2}
            text={restaurante[dato+2].text}
            />     
        </div>

        </div>
    
    )
}

export function constructor2(dato, restaurante){
    return(

        <div className="datosR">
        
        <div className="DatosR">
            <Datos
            image={restaurante[dato].imagen}
            heading= {restaurante[dato].titulo}
            heading2= {restaurante[dato].titulo2}
            text={restaurante[dato].text}
            />
            <Datos
            image={restaurante[dato+1].imagen}
            heading= {restaurante[dato+1].titulo}
            heading2= {restaurante[dato+1].titulo2}
            text={restaurante[dato+1].text}
            />   
        </div>

        </div>
    
    )
}

export function constructor1(dato, restaurante){
    return(

        <div className="datosR">
        
        <div className="DatosR">
            <Datos
            image={restaurante[dato].imagen}
            heading= {restaurante[dato].titulo}
            heading2= {restaurante[dato].titulo2}
            text={restaurante[dato].text}
            /> 
        </div>

        </div>
    
    )
}

export function ender(){
    return(
    
        <div className="datosR">
        <h1>
        Para picar en Sabaneta
        </h1>
        <div className="DatosR">
            <Datos
            image={PR1}
            heading= "El Peregrino"
            
            text="Donde encuentras los buñuelos más famosos del país. "
            />
            <Datos
            image={PR2}
            heading= "Obleas y solteritas del parque"
            
            text="Deléitate con los mejores dulces en todo el parque de Sabaneta. "
            />
            <Datos
            image={PR3}
            heading= "Empanaditas"
            
            text="Las empanadas no pueden faltar, son deliciosas. Puedes encontrarlas en todo el parque de Sabaneta. "
            />     
        </div>
        
    </div>
        
    )
}



/*
function RDatos(){
    return(
    <div className="datosR">
        
        <div className="DatosR">
            <Datos
            image={R1}
            heading= "CIRO BBQ"
            heading2= "Los jueves: 2 hamburguesas, con papas al parmesano y 2 bebidas 250ml por $34.900"
            text="Cra. 47B #76-42. Detrás del Euro/Mall Vegas Plaza. Domicilios en Rappi"
            />
            <Datos
            image={R2}
            heading= "JUAN CHORIZOS"
            heading2= "Los martes: 2 hamburguesas por $20.000 y los miércoles 2 chorizos por $25.000"
            text="Cl. 75 Sur #34 -350, Sabaneta. Domicilios en el 304 660 7693 "
            />
            <Datos
            image={PR4}
            heading= "LA CASA HAMBURGUESERÍA"
            heading2= "La Casa de las Experiencias 🍔"
            text="Calle 72 sur # 43b-36, Sabaneta. Reservas 312 7155031 "
            />     
        </div>
        <div className="DatosR">
           
                     <Datos
            image={PR6}
            heading= "DON TULIO PARRILLA"
            heading2= "Asados en barril y parrilla"
            text="Cl 55A Sur #38-09, María Auxiliadora. Domicilios 305 434 8826 "
            />
            
        </div>
        
        <h1>
        Para picar en Sabaneta
        </h1>
        <div className="DatosR">
            <Datos
            image={PR1}
            heading= "El Peregrino"
            
            text="Donde encuentras los buñuelos más famosos del país. "
            />
            <Datos
            image={PR2}
            heading= "Obleas y solteritas del parque"
            
            text="Deléitate con los mejores dulces en todo el parque de Sabaneta. "
            />
            <Datos
            image={PR3}
            heading= "Empanaditas"
            
            text="Las empanadas no pueden faltar, son deliciosas. Puedes encontrarlas en todo el parque de Sabaneta. "
            />     
        </div>
        
    </div>
    )
    

}
*/
export default RDatos