import ImageSlider from "./ImageSlider";

import im1 from  "../../components/imageSlider3/img/slide1.jpg"
import im2 from  "../../components/imageSlider3/img/slide2.jpg"
import im3 from  "../../components/imageSlider3/img/slide3.jpg"


import "./slider.css"

const SliderData3 = () => {
  const slides = [
    {url: im1} ,
    {url: im2} ,
    {url: im3} 
  ];
 
  return (
    <div>
      
      <div className="slider">
        <ImageSlider slides={slides} parentWidth={500} />
      </div>
    </div>
  );
};

export default SliderData3;