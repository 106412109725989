import "./footer.css"
import Boton from "../boton/Boton";

const Footer =() =>{
    return(
        <div className="footer">
            <div className="first-desF">
                <h1>
                Únete a nosotros:
                </h1>
                <div>
                <Boton
                url="https://www.instagram.com/misabanetacom/"
                text="Síguenos en Instagram"
                />
                </div>
                <div>
                <Boton
                url="https://www.facebook.com/people/MiSabanetaCom/100090220777502/"
                text="Síguenos en Facebook"
                />
                </div>
                <div>
                <Boton
                url="https://www.facebook.com/groups/868879077708628"
                text="Unirse al grupo de Facebook"
                />
                </div>
                
            </div>

        </div>
    )

}

export default Footer