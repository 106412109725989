import React from "react";

import ReactPlayer from "react-player";

import "./youtube.css"

function Youtube({embedId}){
    return(
        <div className="video">
            <ReactPlayer url= {embedId} />
        </div>
    )
}



export default Youtube;