import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import EmpleoImg from "../../components/assets/BlogEmpleo/empleo.jpg"

import EDatos from "../../components/empleoDato/EDatos"
import "./empleo.css"
import Footer from "../../components/Footer/Footer"

import BotonF from "../../components/Formulario/BotonF"

function Empleo() {
  return(
    <div className="Empleo">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {EmpleoImg}
    />
    <EDatos/>
    <div className="datosEmpleo">
      <h1>
      Publica tu oferta de empleo aquí:
      </h1>
      
    </div>
    <div className="first-desEm">
    <BotonF
    url="https://docs.google.com/forms/d/e/1FAIpQLSdc0Srv2Yab3BoQkTay0bndUTmo85dJ91RrLeaBllgYlQptCA/viewform"
    text="Formulario"
    />
    <h1>Si deseas publicar una solicitud de empleo dale click al botón formulario y rellena la información</h1>
                    
                
    </div>
    
    
    <div>

    </div>
    <Footer/>

    </div>
  )
  
}
 export default Empleo;