import ImageSlider from "./ImageSlider";

import im1 from  "../../components/imageSlider2/img/slide1.jpg"
import im2 from  "../../components/imageSlider2/img/slide2.jpg"
import im3 from  "../../components/imageSlider2/img/slide3.jpg"
import im4 from  "../../components/imageSlider2/img/slide4.jpg"

import "./slider.css"

const SliderData2 = () => {
  const slides = [
    {url: im1} ,
    {url: im2} ,
    {url: im3} ,
    {url: im4}
  ];
 
  return (
    <div>
      
      <div className="slider">
        <ImageSlider slides={slides} parentWidth={500} />
      </div>
    </div>
  );
};

export default SliderData2;