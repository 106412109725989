import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import EventosImg from "../../components/assets/Eventos/EventosImg.jpg"
import CalendarSabaneta from "../../components/calendar/Calendar"
import EDatos from "../../components/eventosDato/EDatos"
import "./eventos.css"

import Footer from "../../components/Footer/Footer"


function Eventos() {
  return(
    <div className="Eventos">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {EventosImg}
    />
    <div className="tituloE">
    <h1>
      CALENDARIO
    </h1>
    </div>
    
    <CalendarSabaneta/>
    <EDatos/>
    <Footer/>
    </div>
  )
  
}
 export default Eventos;
 