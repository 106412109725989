import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import OpinionImg from "../../components/assets/Opinion/opinionImg.jpg"
import Datos from "../../components/opinionDato/Datos2"
import Footer from "../../components/Footer/Footer"
import BotonF from "../../components/Formulario/BotonF"
import "./opinion.css"

function Opinon() {
  return(
    <div className="Opinion">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {OpinionImg}
    />
    <div>
      <Datos/>
      
    </div>

    <div className="first-desF">
    <BotonF
    url="https://docs.google.com/forms/d/e/1FAIpQLSey4qfl2sCEqV4qY1sx_2vIu-PZJLB8Utk1EvXs7spmrjiAaQ/viewform"
    text="Formulario"
    />
    <h1>Si deseas publicar tu opinión en este espacio , dale click al botón y contesta el formulario</h1>
                    
                
    </div>


    <Footer/>
    </div>
  )
  
}
 export default Opinon;