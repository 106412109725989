import { Component } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import "./navbar.css"
class Navbar extends Component {
  state = {clicked: false};
  handleClick = () =>{
    this.setState({clicked: !this.state.clicked})
  }
  render(){
  return (
    <nav className="nav">
      <ultitle id="site-title">
      <CustomLink to="/" >
        <h2>MiSabaneta.com</h2>
      </CustomLink>
      </ultitle>
      <div>
        <ul id = "navbar" className={this.state.clicked? "#navbar active" : "#navbar"}>
          <CustomLink to="/">Inicio</CustomLink>
          <CustomLink to="/empleo">Empleo</CustomLink>
          <CustomLink to="/restaurantes">Restaurantes</CustomLink>
          <CustomLink to="/opinion">Opinión</CustomLink>
          <CustomLink to="/eventos">Eventos</CustomLink>
          <CustomLink to="/lugares">Emprendimientos</CustomLink>
        </ul>
      </div>
      <div id = "mobile" onClick={this.handleClick}>
        <i id = "bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
       

      </div>
    </nav>
  )
}
}


function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default Navbar;