import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import LugaresImg from "../../components/assets/Lugares/LugaresImg.jpg"
import LDatos from "../../components/lugaresDato/LDatos"
import "./lugares.css"
import Footer from "../../components/Footer/Footer"

function Lugares() {
  return(
    <div className="Lugares">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {LugaresImg}
    />
    <div className="datos">
      <LDatos/>
    </div>
    <Footer/>
    </div>
  )
  
}
 export default Lugares;