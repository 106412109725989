import "../../components/lugaresDato/datosStyleL.css"
import DatosL from "../../components/lugaresDato/DatosL"

import lu1 from "../../components/assets/Lugares/lugar1.jpg"
import lu2 from "../../components/assets/Lugares/lugar2.jpg"
import lu3 from "../../components/assets/Lugares/lugar3.jpg"
import lu4 from "../../components/assets/Lugares/josefe.jpg"
import lu5 from "../../components/assets/Lugares/apostol.jpg"
import lu6 from "../../components/assets/Lugares/laindependencia.jpg"


import arregloL from "./arregloL"


function LDatos(){
    var dato = 0;
    var emprendimientos= arregloL();
    var emprendimientos_fin = [];
    var lengthE = emprendimientos.length;
    var i = -1;
    while (i < lengthE){
        if (i < 0){
            emprendimientos_fin.push(constructorI())
            i++;
        }
        console.log(emprendimientos.length);
        if((lengthE - dato) >= 3){
            
            emprendimientos_fin.push(constructor(dato,emprendimientos))
            dato += 3;
            i += 3;
        }

        if((lengthE - dato) === 2 ){
            
            emprendimientos_fin.push(constructor2(dato,emprendimientos))
            dato += 2;
            i += 2;
        }

        if((lengthE - dato) === 1 ){
            
            emprendimientos_fin.push(constructor1(dato,emprendimientos))
            dato += 1;
            i += 1;
        }

        
    }

    emprendimientos_fin.push(ender())
    
    return(emprendimientos_fin)
    
}

export function constructorI(){
    return(
        <div className="datosL">
        <h1>Emprendimientos sabaneteños</h1>   
        </div>
    )
}



export function constructor(dato, emprendimientos){
    return(
    <div className="datosL">
        <div className="DatosL">
            <DatosL
            image={emprendimientos[dato].imagen}
            heading= {emprendimientos[dato].titulo}
            text={emprendimientos[dato].text}
            />
            
            <DatosL
            image={emprendimientos[dato+1].imagen}
            heading= {emprendimientos[dato+1].titulo}
            text={emprendimientos[dato+1].text}
            />
            <DatosL
            image={emprendimientos[dato+2].imagen}
            heading= {emprendimientos[dato+2].titulo}
            text={emprendimientos[dato+2].text}
            />
        </div>
    </div>
    )
}

export function constructor2(dato, emprendimientos){
    return(
    <div className="datosL">
        <div className="DatosL">
            <DatosL
            image={emprendimientos[dato].imagen}
            heading= {emprendimientos[dato].titulo}
            text={emprendimientos[dato].text}
            />
            
            <DatosL
            image={emprendimientos[dato+1].imagen}
            heading= {emprendimientos[dato+1].titulo}
            text={emprendimientos[dato+1].text}
            />
        </div>
    </div>
    )
}



export function constructor1(dato, emprendimientos){
    return(
    <div className="datosL">
        <div className="DatosL">
            <DatosL
            image={emprendimientos[dato].imagen}
            heading= {emprendimientos[dato].heading}
            text={emprendimientos[dato].text}
            />
            
        </div>
    </div>
    )
}

export function ender(){
    return(
        
    <div className="datosL">
        <h1>Lugares que debes visitar si vienes a Sabaneta</h1>
        <div className="DatosL">
            <DatosL
            image={lu1}
            heading= "Casa de la cultura La Barquereña"
            text="El mejor lugar para vivir el arte en todas sus formas."
            />
            <DatosL
            image={lu2}
            heading= "Parque de los 4 Elementos"
            text="Es así como evocando un gran bosque y los cuatro elementos de la naturaleza: tierra, aire, agua y fuego; la infraestructura ofrece diferentes tipos de experiencias que estan en constante interacción y movimiento."
            />
            <DatosL
            image={lu3}
            heading= "La Romera   Corporación"
            text="Varios kilómetros a pie para tonificar tus músculos, aliviar el dolor de espalda y fortalecer las articulaciones."
            />
           
        </div>
        <div className="DatosL">
            <DatosL
            image={lu4}
            heading= "Casa de José Felix de Restrepo"
            text="La casa donde nació José Félix de Restrepo, en Sabaneta, en 1883, en el sector de La Doctora, el inmueble no solo es importante porque fue construido hace 250 años, es decir, en la Colonia, fue la casa natal de este personaje, notable en la conformación de la República y además de consejero del libertador Simón Bolívar."
            />
            <DatosL
            image={lu5}
            heading= "Experiencia cervecería Apóstol"
            text="¿Sabe cómo se produce la cerveza artesanal? En esta ruta vivirá todo el proceso cervecero a través de un recorrido por la planta. Despertará sus sentidos acompañado de música y aperitivos (cávanos o quesos) mientras cata las variedades de esta bebida que se fabrica desde hace más de diez años en Sabaneta."
            />
            <DatosL
            image={lu6}
            heading= "La Casa de la Independencia"
            text="No dejes de revisar la oferta cultural que se presenta en este maravilloso espacio lleno de arte, obras de teatro, danza y más..."
            />
           
    
            
        </div>

    </div>   
         
        )
}

/*

function LDatos(){
    return(
<div className="datosL">
        <h1>Emprendimientos sabaneteños</h1>
        <div className="DatosL">
            <DatosL
            image={lu7}
            heading= "Tila Natural Beauty"
            text="Los mejores productos de belleza, cosmética y cuidado de la piel. Solicita su catálogo de skincare, bisutería y más al 305 305 9553 "
            />
            <DatosL
            image={lu8}
            heading= "Barbas Beer"
            text="Esta cerveza es cuidadosamente elaborada, utilizando ingredientes frescos y de calidad para ofrecerte una experiencia única de sabor y aroma. Pedidos al 316 839 5826"
            />
            <DatosL
            image={lu9}
            heading= "Oveja Negra Tattoo"
            text="Un tatuaje es una expresión de tu individualidad y en Oveja Negra Tattoo lo tienen claro. Whatsapp: 314 896 0447"
            />
           
        </div>
        
    <div className="datosL">
        <h1>Lugares que debes visitar si vienes a Sabaneta</h1>
        <div className="DatosL">
            <DatosL
            image={lu1}
            heading= "Casa de la cultura La Barquereña"
            text="El mejor lugar para vivir el arte en todas sus formas."
            />
            <DatosL
            image={lu2}
            heading= "Parque de los 4 Elementos"
            text="Es así como evocando un gran bosque y los cuatro elementos de la naturaleza: tierra, aire, agua y fuego; la infraestructura ofrece diferentes tipos de experiencias que estan en constante interacción y movimiento."
            />
            <DatosL
            image={lu3}
            heading= "La Romera   Corporación"
            text="Varios kilómetros a pie para tonificar tus músculos, aliviar el dolor de espalda y fortalecer las articulaciones."
            />
           
        </div>
        <div className="DatosL">
            <DatosL
            image={lu4}
            heading= "Casa de José Felix de Restrepo"
            text="La casa donde nació José Félix de Restrepo, en Sabaneta, en 1883, en el sector de La Doctora, el inmueble no solo es importante porque fue construido hace 250 años, es decir, en la Colonia, fue la casa natal de este personaje, notable en la conformación de la República y además de consejero del libertador Simón Bolívar."
            />
            <DatosL
            image={lu5}
            heading= "Experiencia cervecería Apóstol"
            text="¿Sabe cómo se produce la cerveza artesanal? En esta ruta vivirá todo el proceso cervecero a través de un recorrido por la planta. Despertará sus sentidos acompañado de música y aperitivos (cávanos o quesos) mientras cata las variedades de esta bebida que se fabrica desde hace más de diez años en Sabaneta."
            />
            <DatosL
            image={lu6}
            heading= "La Casa de la Independencia"
            text="No dejes de revisar la oferta cultural que se presenta en este maravilloso espacio lleno de arte, obras de teatro, danza y más..."
            />
           
    
            
        </div>

         </div>   
         </div>
    )
    

}
*/
export default LDatos