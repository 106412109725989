import "./HeroStyles.css"

function Hero(props) {
  return (
    <>
    <div className={props.cName}>
        <img alt ="HeroImg" className="HeroImg" src={props.heroImg}/>
        <div className="hero-text">
            <h1>
                {props.text1}
            </h1>
            

        </div>

    </div>
    </>
  )
}

export default Hero;
