import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import RestauranteImg from "../../components/assets/restaurantes/RestaurantesImg.jpg"
import "./restaurantes.css"

import Youtube from "../../components/video/Youtube"

import RDatos from "../../components/restaurantesDatos/RDatos"

import BotonF from "../../components/Formulario/BotonF"

import Footer from "../../components/Footer/Footer"

function Restaurantes() {
  return(
    <div className="Restaurantes">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {RestauranteImg}
    />
    <h1>
    Donde comer en Sabaneta...
    </h1>
    <h2>
    "Tripa vacía, corazón sin alegría"
    </h2>
    <div className="first-desV">
    </div>
    <RDatos/>

    <div className="first-desF">
    <BotonF
    url="https://docs.google.com/forms/d/e/1FAIpQLSe_4E_0GKRw5IqdojZg0tI0OIjWdfi3q-lBF9TcKUbFzrB__Q/viewform"
    text="Formulario"
    />
    <h1>Si deseas que tu restaurante este aquí, dale click al botón y contesta el formulario</h1>
                    
    <Youtube embedId="https://www.youtube.com/watch?v=Gaz5dqQf1oY"/>      
    </div>
    <Footer/>
    </div>
  )
  
}
 export default Restaurantes;