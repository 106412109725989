

import "../lugaresDato/datosStyleL.css"

function DatosL(props){
    return(
        <div className="t-cardL">
            <div className="t-imageL">
                <img src={props.image} alt="img"/>
            </div>
            <h4>
                {props.heading}
            </h4>
            <p>
                {props.text}
            </p>
            
        </div>

    )
    
}

export default DatosL;