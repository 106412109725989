import Empleo from "./pages/empleo/Empleo"
import Inicio from "./pages/inicio/Inicio"
import Restaurantes from "./pages/restaurantes/Restaurantes"
import Opinion from "./pages/opinion/Opinion"
import Eventos from "./pages/eventos/Eventos"
import Lugares from "./pages/lugares/Lugares"
import "./styles.css"
import { Route, Routes } from "react-router-dom"

function App() {
  return (
    <>
      <div className="container">
        <Routes>
          <Route path="/" element={<Inicio />}/>
          <Route path="/empleo" element={<Empleo />} />
          <Route path="/restaurantes" element={<Restaurantes />} />
          <Route path="/opinion" element={<Opinion />} />
          <Route path="/eventos" element={<Eventos />} />
          <Route path="/lugares" element={<Lugares />} />
        </Routes>
      </div>
    </>
  )
}

export default App
