import React from "react";

import "./boton.css"


function Boton(props){
    return(
        <div className="Boton">
        <a href={props.url}>
        <button>
            {props.text}
        </button>
        </a>
        
        </div>
    )
    

}

export default Boton;