import Navbar from "../../components/navbar/Navbar"
import Hero from "../../components/hero/Hero"
import headImg from "../../components/assets/imgInicio/headImg.jpg"
import Datos from "../../components/InicioDato/Datos"
import Footer from "../../components/Footer/Footer"
import "./inicio.css"
function Inicio() {
  return (
    <div className="Inicio">
    <Navbar />
    <Hero
    cName="hero"
    heroImg= {headImg}
    />
    <heroPortada />
    <div>
      <Datos/>
    </div>

    <div>
      <Footer/>
    </div>

    
    </div>
  )
}

export default Inicio;
