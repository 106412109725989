import React from "react";

import "./botonF.css"


function BotonF(props){
    return(
        <div className="BotonF">
        <a href={props.url}>
        <button>
            {props.text}
        </button>
        </a>
        
        </div>
    )
    

}

export default BotonF;