import Boton from "../boton/Boton";
import "./datosStyle.css"

import im1 from "../../components/assets/imgInicio/sabaneta.jpg"
import im2 from "../../components/assets/imgInicio/sabanetahoy.jpg"
import im3 from "../../components/assets/imgInicio/coordenadas.JPG"

import SliderData2 from "../imageSlider2/SliderData"
import SliderData3 from "../imageSlider3/SliderData"
import SliderData4 from "../imageSlider4/SliderData"
import SliderData5 from "../imageSlider5/SliderData"



import Youtube from "../../components/video/Youtube"
const Datos = () =>{
    return(
        <div className="datosI">
            <div>
                <div className="first-desI">
                
            
                <div className="Iboton">
                <Boton
                url="https://www.instagram.com/misabanetacom/"
                text="Cuenta Instagram"
                />
                </div>
                <div className="Iboton">
                <Boton
                url="https://www.facebook.com/groups/868879077708628"
                text="Grupo Facebook"
                />
                </div>
                <div className="Iboton">
                <Boton
                url="https://www.facebook.com/people/MiSabanetaCom/100090220777502/"
                text="Página Facebook"
                />
                </div>
                <h1>
                Síguenos en nuestras redes sociales y descubre la mejor comunidad de Sabaneta.
                </h1>
                </div>

                <div className="first-desIn">
                    
                    <div  className="imageI">
                    <img src={im1} alt="img"/>
                    <p>
                        Sabaneta año 2000
                    </p>
                    
                    </div>
                    
                    
                    <div  className="imageI">
                    <img src={im2} alt="img"/>
                    <p>
                        Sabaneta hoy
                    </p>
                    </div>
                    

                </div>  
                
            </div>
            <h2>
            El Vallecito del encanto, así es llamado Sabaneta, gracias a que es el municipio más pequeño del país, pero grande en desarrollo y calidad de vida.
            </h2>

           
            <div className="first-desIn">
                <div className="slider">
                <SliderData2/>
                </div>
                <div className="slider">
                <SliderData3/>
                </div>
            </div> 
             <h1>
            Un poco de su historia...
            </h1>
            <h2>
            Dice el Gobernador y Capitán General de la Provincia de Antioquia Don Francisco Carillo de Albornoz, en el Decreto en que fundó La Estrella, que:

"...He tenido por bien de ambas majestades y bien de la causa pública , reducir y congregar dichos indios Anaconas a población y para ello, en virtud de la facultad y poderes reales que tengo desde luego: elijo y crío pueblo con nombre de Nuestra Señora de la Estrella en el sitio que piden de la Sabaneta..." 

4 de septiembre de 1685.

Es este uno de los documentos más antiguos, de que se tenga noticia, en donde se hace referencia al territorio que hoy ocupa Sabaneta.
            </h2>
            
            <div className="first-desIn">
                <div className="videoI">
                <Youtube embedId="https://www.youtube.com/watch?v=mrMEQuuhYdU"/>
                </div>
            
                <div className="Vslider">
                <SliderData4/>
                </div>
            </div>

            <div className="first-desIn">

                    
                    <div  className="imageI">
                    <img src={im3} alt="img"/>
                    </div>

                    <h2>

Nuestro municipio cuenta con 31 barrios y 6 veredas, en cada uno de sus sectores el ambiente es tan acogedor que dan ganar de quedarse a vivir en ellos. Se respira en sus calles la paz de los pequeños pueblos, que se conecta fácilmente al agitado centro de Medellín.
                    </h2>
                </div>

            <div className="first-desIn">
                <div>
                <h2>
                Una de las ventajas de vivir en Sabaneta es disfrutar de su gastronomía, en especial, de los buñuelos más grandes y deliciosos que tiene el país. Ah, y ni qué decir de las fondas de Sabaneta, ¡son una representación clara de la cultura paisa! 
                </h2>
                </div>
                <div>
                <SliderData5/>
                </div>
            </div>
            

        </div>
    )

}

export default Datos;