

import "./datosStyleR.css"

function Datos(props){
    return(
        <div className="t-cardR">
            <div className="t-imageR">
                <img src={props.image} alt="images"/>
            </div>
            <h4>
                {props.heading}
            </h4>
            <h5>
                {props.heading2}
            </h5>
            <p>
                {props.text}
            </p>
            
        </div>

    )
    
}

export default Datos;