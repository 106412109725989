import React, { useState } from "react";


import "../../components/opinionDato/datosStyleO.css"

import "../../components/opinionDato/readmore.css"

import arregloO from "./arregloO";

function EDatos(){
    var dato = 0;
    var opinion= arregloO();
    var enventos_fin = [];
    var lengthO = opinion.length;
    //var tipo = 0;
    var i = 0;
    while (i < lengthO){
        if (i < 0){
            enventos_fin.push(constructorI())
            i=0;
        }
        
        if((lengthO-dato)>= 2){
            
            enventos_fin.push(ConstructorN(dato,opinion))
            enventos_fin.push(ConstructorR((dato+1),opinion))
            dato += 2;
            i += 2;
            
            
        }

        if((lengthO-dato)===1){
            
            enventos_fin.push(ConstructorN(dato,opinion))
            dato += 1;
            i += 3;
 
        }
    }

    
    return(enventos_fin)
    
}

export function constructorI(){
    return(
        <div className="datosO">
            <div>
                <h1>
                    Opinión
                </h1>
            </div>
        </div>
    )
}




export function ConstructorN(dato, opinion){
    
    const [isShowMore, setIsShowMore] = useState(false);
    
    const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);

    };    
    return(
        <div className="datosO">
        <div>
            <div className="first-des-r">
                <div className="image">
                    <img alt="img" src={opinion[dato].imagen}/>
                </div>
                <div className="des-textO">
                    <h2>


                    {opinion[dato].titulo}  
                    
                    </h2>
                    <p>
                    {opinion[dato].text}
                    </p>
                    {isShowMore && (
                        <p>
                        {opinion[dato].textomas}
                        </p>
                    )}

                    <button className = "btn" onClick={toggleReadMoreLess}>
                        {isShowMore ? "...Leer menos" : "leer más..."}
                    </button>
                    

            </div>
            </div>    
        </div>
        </div>
    )
}


export function ConstructorR(dato, opinion){
    const [isShowMore, setIsShowMore] = useState(false);
    
    const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
    }; 
    return(
        <div className="datosO">
        <div>

            <div className="first-des-ra">
                <div className="des-textO">
                        <h2>
                        {opinion[dato].titulo}
                        </h2>
                        <p>
                        {opinion[dato].text}
                        </p>
                        {isShowMore && (
                            <p>
                            {opinion[dato].textomas}
                            </p>
                        )}

                        <button className = "btn" onClick={toggleReadMoreLess}>
                            {isShowMore ? "...Leer menos" : "leer más..."}
                        </button>
                        

                </div>
                <div className="image">
                    <img alt="img" src={opinion[dato].imagen}/>
                </div>
                </div>


    
        </div>
        </div>
    )
}


/*

const Datos = () =>{

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
    };
    



    return(
        
        <div className="datosO">
            <div>
                <h1>
                    Opinión
                </h1>
                
                <div className="first-des-r">
                <div className="image">
                        <img alt="img" src={op1}/>
                    </div>
                    <div className="des-textO">
                        <h2>
                        EL MEJOR VIVIDERO DEL ÁREA METROPOLITANA
                        </h2>
                        <p>
                        Sabaneta es un municipio que cautiva a primera vista; un lugar muy “titino”, como dirían los paisas.
                        </p>
                        {isShowMore && (
                            <p>
                            <strike>como dirían los paisas. Su encanto nace de la particular combinación </strike>entre pueblo y ciudad,  con lo mejor de uno y otro. Con 15 kilómetros cuadrados de extensión es el municipio más pequeño de Colombia. No obstante, las opciones para pasarla bien y acceder a “todos los juguetes” de una gran ciudad son numerosas.<br/> 

                            Así, el municipio ha propiciado una buena parte de la venta de viviendas nuevas en el sur del Valle de Aburrá. Muchas familias han optado por asentarse en la localidad y vivir rodeadas de montañas y naturaleza. Exóticas zonas veredales e incluso áreas de reserva forestal se distribuyen con el área urbana.<br/> 

                            A Sabaneta solo la separa de Medellín el municipio de Envigado. Por eso, desplazarse hacia la capital antioqueña toma unos 15 minutos en carro. El municipio tiene acceso a importantes vías regionales que cruzan de sur a norte gran parte del área metropolitana. Puedes llegar y salir a través de la avenida Las Vegas, El Poblado o la Avenida Regional.
                            </p>
                        )}

                        <button className = "btn" onClick={toggleReadMoreLess}>
                            {isShowMore ? "...Leer menos" : "leer más..."}
                        </button>
                        

                    </div>
                    
                </div>



                <div className="first-des-ra">
                    <div className="des-textO">
                        <h2>
                        BIBLIOTECA PÚBLICA MUNICIPAL 
                        </h2>
                        <h2>
                        Juan Carlos Montoya Montoya
                        </h2>
                        <p>
                        Las bibliotecas son una de las instituciones más valiosas de nuestra sociedad. Desde tiempos antiguos, han sido un lugar para el aprendizaje, la investigación y la exploración. Hoy en día, las bibliotecas desempeñan un papel crucial en la educación y en la promoción de la alfabetización y el conocimiento.
                        </p>
                        {isShowMore && (
                            <p>
                            Son importantes porque proporcionan acceso a una amplia variedad de recursos de información. Las bibliotecas no solo tienen libros, sino también revistas, periódicos, documentos gubernamentales, mapas y archivos de audio y video. Estos recursos son valiosos para investigadores, estudiantes y cualquier persona que esté interesada en aprender y ampliar sus conocimientos.<br/>
                            Además, las bibliotecas ofrecen servicios de referencia y asistencia a los usuarios. Los bibliotecarios están capacitados para ayudar a las personas a encontrar la información que necesitan y a navegar por los recursos de la biblioteca. Los bibliotecarios también pueden ayudar a las personas a desarrollar habilidades de investigación y a evaluar la calidad de la información que encuentran en línea.<br/>
                            Otra razón por la cual las bibliotecas son importantes es porque son un lugar seguro y accesible para la comunidad. Las bibliotecas son lugares donde las personas pueden reunirse y conectarse en un ambiente seguro y sin prejuicios. También son espacios para estudiar, trabajar y relajarse, y también organizan opinion y programas para la comunidad.<br/>
                            En resumen, las bibliotecas son una parte fundamental de nuestra sociedad.
                            
                            Proporcionan acceso a una amplia variedad de recursos de información, ofrecen servicios de referencia y asistencia a los usuarios, fomentan la alfabetización y el amor por la lectura, y son un lugar seguro y accesible para la comunidad. Es importante que sigamos apoyando y promoviendo nuestras bibliotecas públicas para que puedan seguir desempeñando su papel vital en la educación y el conocimiento de nuestra sociedad.<br/>
                            ¿Qué tenemos sin bibliotecas? No tenemos pasado y no tenemos futuro”RayBradbury.
                            </p>
                        )}

                        <button className = "btn" onClick={toggleReadMoreLess}>
                            {isShowMore ? "...Leer menos" : "leer más..."}
                        </button>

                    </div>
                    <div className="image">
                        <img alt="img" src={op2}/>
                    </div>
                </div>


                <div className="first-des-r">
                <div className="image">
                        <img alt="img" src={op3}/>
                    </div>
                    <div className="des-textO">
                        <h2>
                        EL PROBLEMA DE LAS BASURAS EN SABANETA: Una situación que nos concierne a todos
                        </h2>
                        <p>
                        Uno de los principales problemas que enfrentamos en nuestro municipio es la acumulación de basuras en áreas públicas y calles. En el barrio Villas del Carmen por ejemplo, muchos vecinos sienten esta problemática al ver acumuladas las basuras en las esquinas , sin respetar los horarios de recolección de basura o el adecuado uso de los contenedores dispuestos para tal fin. Esto ha generado la formación de vertederos improvisados, que además de ser antiestéticos, emiten olores desagradables y atraen plagas de insectos y roedores. Esto representa un riesgo para la salud de los habitantes del barrio, así como una imagen negativa para nuestra comunidad.
                        </p>
                        {isShowMore && (
                            <p>
                            Desde Misabaneta.com queremos hacer un llamado a las autoridades locales a que pongan el ojo en esta problemática, pero también hacemos un llamado a la comunidad ya que es un tema que nos concierne a todos. Es responsabilidad de cada uno de nosotros adoptar prácticas adecuadas de manejo de los residuos sólidos, como separarlos correctamente en nuestras casas, respetar los horarios de recolección y hacer uso adecuado de los contenedores dispuestos para tal fin. También es fundamental promover la concientización y educación sobre la importancia del reciclaje y la reducción de la generación de basuras.<br/>

                            Es necesario que trabajemos juntos como comunidad, en coordinación con las autoridades locales y otros actores relevantes, para implementar soluciones efectivas que aborden esta problemática.<br/>

                            Publicado por: Misabaneta.com 
                            </p>
                        )}

                        <button className = "btn" onClick={toggleReadMoreLess}>
                            {isShowMore ? "...Leer menos" : "leer más..."}
                        </button>
                        
                    </div>
                    </div>
                  
                
                
                    
                    <div className="first-des-ra">
                    <div className="des-textO">
                        <h2>
                        DENUNCIA CIUDADANA 
                        </h2>
                        <h2>
                        Cuidemos nuestras fuentes hídricas
                        </h2>

                        <p>
                        Así se veía la quebrada La Honda o Palenque el 17 de Abril. Desde la ciudadanía se han interpuesto quejas de esta situación también para las quebradas la Sabanetica y las Lomitas. Pero la autoridad competente parece hacer caso omiso a estas denuncias. Desde Misabaneta.com hacemos un llamado a la preservación de nuestras fuentes hídricas, de riachuelos, quebradas y ríos que atraviesan nuestro municipio, para que no se conviertan en vertederos de desechos.
                        </p>
                        {isShowMore && (
                            <p>
                            Recordemos que es el habitat de cientos de especies 🙌🐿🐾🐇🐢🐍🦎 <br/>

                            Publicado por: Misabaneta.com
                            </p>
                        )}

                        <button className = "btn" onClick={toggleReadMoreLess}>
                            {isShowMore ? "...Leer menos" : "leer más..."}
                        </button>
                        

                    </div>
                    <div className="image">
                        <img alt="img" src={op4}/>
                    </div>
                   
                    
                </div>

            </div>

        </div>



    )

}
*/

export default EDatos;