import ev2 from "../../components/assets/Eventos/ev2.jpg"
import ev5 from "../../components/assets/Eventos/ev5.jpg"
import ev6 from "../../components/assets/Eventos/ev6.jpg"

//import ev4 from "../../components/assets/Eventos/carrera.jpg"
//import evento from "../../components/assets/Eventos/Ven a vivir.png"
import evento2 from "../../components/assets/Eventos/TEATRO INFA.png"
//import evento3 from "../../components/assets/Eventos/semanadanza1.jpg"
//import evento4 from "../../components/assets/Eventos/semanadanza2.jpg"
//import evento5 from "../../components/assets/Eventos/carrera.jpg"
import evento6 from "../../components/assets/Eventos/domingo.jpg"
import evento7 from "../../components/assets/Eventos/consultoriofilo.png"
import evento8 from "../../components/assets/Eventos/ev11.jpg"
import evento9 from "../../components/assets/Eventos/ev12.jpg"
import evento10 from "../../components/assets/Eventos/ev13.jpg"
import evento11 from "../../components/assets/Eventos/ev14.png"
import evento12 from "../../components/assets/Eventos/sabd20.jpg"
import evento13 from "../../components/assets/Eventos/TEATRO.jpg"
import evento14 from "../../components/assets/Eventos/fiestaplatano1.jpg"
import evento15 from "../../components/assets/Eventos/fiestaplatano2.jpg"
import evento16 from "../../components/assets/Eventos/fiestaplatano3.jpg"
import evento17 from "../../components/assets/Eventos/fiestaplatano4.jpg"
import evento18 from "../../components/assets/Eventos/fiestaplatano5.jpg"
import evento19 from "../../components/assets/Eventos/elviciodepensar.jpg"
import evento20 from "../../components/assets/Eventos/labarquere.jpg"
import evento21 from "../../components/assets/Eventos/laromera.jpg"
import evento22 from "../../components/assets/Eventos/ELVICIODEPENSASEP.jpg"


//import ev5 from "../../components/assets/Eventos/ev5.jpg"
//import ev6 from "../../components/assets/Eventos/ev6.jpg"
//import ev7 from "../../components/assets/Eventos/ev7.jpg"
//import ev8 from "../../components/assets/Eventos/ev8.jpg"
//import ev9 from "../../components/assets/Eventos/ev9.jpg"

//import ev11 from "../../components/assets/Eventos/semanadanza2.jpg"
//import ev12 from "../../components/assets/Eventos/camin.jpg"



function arreglo(){
  const moment = require("moment")

  let array = [
    { fechas: "29-4",
      imagen: evento11, 
      heading: "SERENATAS DIA DE LAS MADRES MAYO",
      text: "Invitan Casa de la Cultura la Barquereña"
    },

    {
      fechas: "29-4",
      imagen: ev2,
      heading: "Talleres creativos para adultos y niños",
      text:"Abril- Biblioteca Pública Municipal"
    },

    {
      fechas: "12-4",
      imagen: evento10,
      heading: "CUENTERÍA: El trajín de un supuesto artista",
      text:"viernes 12 de mayo 7.30 pm. Aporte 10k"
    },
    {
      fechas: "29-4",
      imagen: evento2,
      heading: "Noche de teatro infantil",
      text: "Abril 29, en el teatro municipal Leonor Diaz Montoya. 8:00 PM"
    },
    {
      fechas: "12-4",
      imagen: evento8,
      heading: "NOCHE DE TEATRO: Botacampana - Poli Escénico",
      text: "Sábado 13 de mayo- 8:00 pm"
    },
    {
      fechas: "28-4",
      imagen: evento6,
      heading: "Caminatas todos los domingos con La Romera",
      text: "Informes en el Whastapp 300 320 37 84"
    },
    {
      fechas: "23-4",
      imagen: evento9,
      heading: "MUJERES EMPRENDEDORAS SABANETA",
      text: "Mayo 18. Hora: 1:00 pm. Teatro Municipal Leonor Diaz Montoya"
    },
    {
      fechas: "24-5",
      imagen: ev5,
      heading: "Fiestas de María Auxiliadora",
      text: "Mayo 24"
    },
    {
      fechas: "18-6",
      imagen: ev6,
      heading: "Fiestas de Santa Ana",
      text: "Mayo 24"
    },
    {
      fechas: "20-4",
      imagen: evento7,
      heading: "Consultorio filosófico",
      text: "Mayo 12"
    },
    {
      fechas: "11-5",
      imagen: evento12,
      heading: "TEATRO: RED SOCIAL-PARAÍSO OSCURO",
      text: "Mayo 20- 8PM- Teatro municipal Leonor Diaz"
    },
    {
      fechas: "11-4",
      imagen: evento13,
      heading: "NOCHES DE TEATRO: EL CORAZÓN DE DAMIAN ROCA",
      text: "JUNIO 17- 8PM- Teatro municipal Leonor Diaz"
    },
    {
      fechas: "11-3",
      imagen: evento14,
      heading: "Conversatorio con el Poeta: Dario Jaramillo",
      text: "SABADO 1 JULIO- 4PM- CASA DE CULTURA LA BARQUEREÑA"
    },
    {
      fechas: "11-3",
      imagen: evento15,
      heading: "Concierto. The Beat Side - Yo me llamo",
      text: "Viernes 30 JUNIO- 8PM- PARQUE ERATO"
    },
    {
      fechas: "11-3",
      imagen: evento16,
      heading: "3er Aniversario: Titanes del Rock de Veracruz Stereo",
      text: "Sábado 1 JULIO- 3PM A 8PM- PARQUE ERATO"
    },
    {
      fechas: "11-3",
      imagen: evento17,
      heading: "TOMA CULTURAL: LUZ VERDE PARA EL ARTE INDEPENDIENTE",
      text: "27 Y 28 DE JUNIO- 2PM- CARRERA 45 EN CALLES 71 Y 72  SUR"
    },
    {
      fechas: "11-3",
      imagen: evento18,
      heading: "DESFILE INAUGURAL DE LAS FIESTAS DEL PLÁTANO",
      text: " MARTES 27 DE JUNIO- 7PM- VIAS PRINCIPALES DE SABANETA"
    },
    {
      fechas: "11-1",
      imagen: evento19,
      heading: "CONSULTORIO FILOSOFICO EL VICIO DE PENSAR",
      text: " VIERNES 7 DE JULIO 8:00PM. JAC LA FLORIDA"
    },
    {
      fechas: "11-1",
      imagen: evento20,
      heading: "INTERCAMBIO CULTURAL DE DANZA CON COSTA RICA",
      text: "SABADO 8 DE JULIO 7:00PM. AUDITORIO BIBLIOTECA 4TO PISO"
    },
    {
      fechas: "11-1",
      imagen: evento21,
      heading: "CAMINATA ECOLOGICA CASCADA LA BRIZUELA EN GUARNE",
      text: " DOMINGO 9 DE JULIO- INSCRIBETE EN EL: 300 320 3784"
    },
    {
      fechas: "9-1",
      imagen: evento22,
      heading: "CONSULTORIO FILOSOFICO NIETZCHE",
      text: " VIERNES 1 DE SEPTIEMBRE JAC LA FLORIDA"
    },

  ];

  const sortedArray = array.sort((a, b) => moment(a.fechas, "DD-MM").unix() - moment(b.fechas, "DD-MM").unix());
  
  return(
    
    sortedArray

  )
}

export default arreglo;
/*
hola = arreglo()
hola2 = hola[0]
console.log(hola[0].heading)*/