
import lu7 from "../../components/assets/Lugares/tila (2).jpg"
import lu8 from "../../components/assets/Lugares/barbas.jpg"
import lu9 from "../../components/assets/Lugares/oveja.jpg"
import lu10 from "../../components/assets/Lugares/selvati22.png"
import lu11 from "../../components/assets/Lugares/chilan.png"
import lu12 from "../../components/assets/Lugares/anderson.png"
import lu13 from "../../components/assets/Lugares/MERAKII.png"
import lu14 from "../../components/assets/Lugares/MAEMUKI.png"
import lu15 from "../../components/assets/Lugares/ROBOT.jpeg"
import lu16 from "../../components/assets/Lugares/yoamo.png"
import lu17 from "../../components/assets/Lugares/OHANA.jpeg"

function arregloL(){
  const moment = require("moment")

  let array = [
    { fechas: "27-2",
      imagen: lu7, 
      titulo: "TILA NATURAL BEAUTY",
      text: "Los mejores productos de belleza, cosmética y cuidado de la piel. Solicita su catálogo de skincare, bisutería y más al 305 305 9553",

    },
    { fechas: "27-2",
      imagen: lu8, 
      titulo: "BARBAS BEER",
      text: "Esta cerveza es cuidadosamente elaborada, utilizando ingredientes frescos y de calidad para ofrecerte una experiencia única de sabor y aroma. Pedidos al 316 839 5826",
    },
    { fechas: "27-2",
      imagen: lu9, 
      titulo: "OVEJA NEGRA TATOO",
      text: " OVEJA NEGRA TATOO: Un tatuaje es una expresión de tu individualidad y en Oveja Negra Tattoo lo tienen claro. Whatsapp: 314 896 0447",

    },
    { fechas: "09-5",
    imagen: lu10, 
    titulo: "SELVATI",
    text: "Frutas y verduras empacadas al vacío. Alimentación inteligente. Whatsapp: 3132179040",

  },
  { fechas: "09-5",
  imagen: lu11, 
  titulo: "CHIMILANTRO",
  text: "Acompaña tus comidas y agrega ese toque especial a tus platos favoritos. 😋. Whatsapp: 300 865 7662",

},
{ fechas: "09-4",
      imagen: lu12, 
      titulo: "ANDERSON MORA CONSULTORES",
      text: "Outsorcing contable, declaraciones de renta, revisoria fiscal, asesoría contable y tributaria. Contacto  300 323 9356",
    },
    { fechas: "09-4",
    imagen: lu13, 
    titulo: "MERAKI",
    text: "Un espacio para trabajar y conectarte con lo que disfrutas hacer, dejando una parte de tu alma en ello 💜💙💛🤩. Contacto  301 5835248",
  },
  { fechas: "09-6",
    imagen: lu14, 
    titulo: "MAEMUKI",
    text: "Conoce sus redes sociales y explora un espectacular catálogo con accesorios económicos y únicos, hechos con amor y dedicación. 🙌💍. Contacto  316 7340527",
  },
  { fechas: "09-6",
  imagen: lu15, 
  titulo: "ROBOTILASER",
  text: "Corte y grabado laser en madera, mdf, acrílico, cartón,textil, cuero, Etc. 🙌. Contacto  321 882 7312",
},
{ fechas: "09-6",
imagen: lu16, 
titulo: "YO AMO LOS PRODUCTOS DE AQUÌ",
text: "Comercializadora de alimentos. Del campo de aquí, de la gente de aquí. Contacto  315 476 8968",
},
{ fechas: "09-6",
imagen: lu17, 
titulo: "OHANA",
text: "Etología/medicina del comportamiento - educación para cachorros. Contacto  311 647 9251",
},
  ];

  const sortedArray = array.sort((a, b) => moment(b.fechas, "DD-MM").unix() - moment(a.fechas, "DD-MM").unix());
  
  return(
    
    sortedArray

  )
}

export default arregloL;
/*
hola = arreglo()
hola2 = hola[0]
console.log(hola[0].heading)*/