import R1 from "../../components/assets/restaurantes/R1.jpg"
import R2 from "../../components/assets/restaurantes/R2.jpg"

import PR4 from "../../components/assets/restaurantes/lacasa.jpg"
import PR6 from "../../components/assets/restaurantes/rr.jpg"
import PR7 from "../../components/assets/restaurantes/color.png"
import PR8 from "../../components/assets/restaurantes/tacovers1.png"
import PR9 from "../../components/assets/restaurantes/CANIBAL.png"
import PR10 from "../../components/assets/restaurantes/gill.jpeg"
import PR11 from "../../components/assets/restaurantes/Healthyfood.jpeg"
import PR12 from "../../components/assets/restaurantes/lacasa.jpeg"
import PR13 from "../../components/assets/restaurantes/incaexpress.jpg"
import PR14 from "../../components/assets/restaurantes/Copia de post curazao.png"

function arregloR(){
  const moment = require("moment")

  let array = [
    { fechas: "27-2",
      imagen: R1, 
      titulo: "CIRO BBQ",
      titulo2: "2 hamburguesas, con papas al parmesano y 2 bebidas 250ml por $34.900",
      text: "Cra. 47B #76-42. Detrás del Euro/Mall Vegas Plaza. Domicilios en Rappi",

    },
    { fechas: "27-2",
      imagen: R2, 
      titulo: "JUAN CHORIZOS",
      titulo2: "Los martes: 2 hamburguesas por $20.000 y los miércoles 2 chorizos por $25.000",
      text: "Cl. 75 Sur #34 -350, Sabaneta. Domicilios en el 304 660 7693 ",

    },
  
    { fechas: "27-2",
      imagen: PR6, 
      titulo: "DON TULIO PARRILLA",
      titulo2: "Asados en barril y parrilla",
      text: "Cl 55A Sur #38-09, María Auxiliadora. Domicilios 305 434 8826",

    },
    { fechas: "09-5",
      imagen: PR7, 
      titulo: "EL DESQUITE CON ESQUITE",
      titulo2: "Amor mexicano en vasito",
      text: "Carrera 46 # 74 sur 79. Domicilios en Rappi",

    },
    { fechas: "09-5",
    imagen: PR8, 
    titulo: "TACOVERS",
    titulo2: "Miércoles de 2 hamburguesas con papas más gaseosa por $29.900.",
    text: "Domicilios: 319 7306335",
    },
    { fechas: "09-6",
    imagen: PR9, 
    titulo: "CANIBAL",
    titulo2: "Oficialmente la mejor hamburguesa de Sabaneta. GANADORA DEL BURGUER MASTER 2023",
    text: "Domicilios: 300 7702444",
    },
    { fechas: "10-6",
    imagen: PR10, 
    titulo: "SABANETA GRILL",
    titulo2: "Un nuevo concepto de parrilla interactiva en Sabaneta",
    text: "Calle 70 sur #46-106 a dos cuadras del parque principal de Sabaneta",
    },
    { fechas: "08-6",
    imagen: PR11, 
    titulo: "HEALTHY FOODS",
    titulo2: "Desayunos, bebidas y snacks saludables",
    text: "Al frente de la cancha de Entreamigos",
    },
    { fechas: "10-6",
    imagen: PR12, 
    titulo: "LA CASA HAMBURGUESERÌA",
    titulo2: "La casa de las experiencias: deliciosas hamburguesas, almuerzos y desayunos",
    text: "Calle 72 sur #43b36 a una cuadra del parque principal de Sabaneta",
    },
    { fechas: "10-6",
    imagen: PR13, 
    titulo: "INCA EXPRESS",
    titulo2: "La mejor gastronomía peruana en Sabaneta",
    text: "Cc Aves María, zona bistro local 347",
    },
    { fechas: "10-7",
    imagen: PR14, 
    titulo: "PINEAPPLE TOUCH",
    titulo2: "HELADERIA NUEVA EN SABANETA",
    text: "Cll 69 #46-33",
    },
   
  ];

  const sortedArray = array.sort((a, b) => moment(b.fechas, "DD-MM").unix() - moment(a.fechas, "DD-MM").unix());
  
  return(
    
    sortedArray

  )
}

export default arregloR;
/*
hola = arreglo()
hola2 = hola[0]
console.log(hola[0].heading)*/