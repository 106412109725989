
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "./calendar.css";

import moment from "moment";


require('moment/locale/es.js')

const localizer = momentLocalizer(moment)

const events = [
    {
        title: "Noche de teatro: El quijotesco Alonso",
        allDay: true,
        start: new Date(2023, 3, 15),
        end: new Date(2023, 3, 15),
    },
    {
        title: "Taller creativo niños",
        start: new Date(2023, 3, 11),
        end: new Date(2023, 3, 11),
    },
    {
        title: "Taller creativo niños",
        start: new Date(2023, 3, 25),
        end: new Date(2023, 3, 25),
    },
    {
        title: "Taller creativo adultos",
        start: new Date(2023, 3, 14),
        end: new Date(2023, 3, 14),
    },
    {
        title: "Taller creativo adultos",
        start: new Date(2023, 3, 28),
        end: new Date(2023, 3, 28),
    },
    {
        title: "Caminata ecológica",
        start: new Date(2023, 3, 16),
        end: new Date(2023, 3, 16),
    },
    {
        title: "Carrera de observación",
        start: new Date(2023, 3, 23),
        end: new Date(2023, 3, 23),
    },
    {
        title: "Fiestas Maria Auxiliadora",
        start: new Date(2023, 4, 24),
        end: new Date(2023, 4, 24),
    },
    {
        title: "Fiestas de Santa Ana",
        start: new Date(2023, 5, 18),
        end: new Date(2023, 5, 26),
    },
    {
        title: "Batalla y muestra de danza urbana",
        start: new Date(2023, 3, 22),
        end: new Date(2023, 3, 22),
    },
    {
        title: "Parrovia",
        start: new Date(2023, 3, 23),
        end: new Date(2023, 3, 23),
    },
    {
        title: "Talleres de bachata",
        start: new Date(2023, 3, 24),
        end: new Date(2023, 3, 24),
    },
    {
        title: "Talleres de tango",
        start: new Date(2023, 3, 25),
        end: new Date(2023, 3, 25),
    },
    {
        title: "Talleres danza árabe",
        start: new Date(2023, 3, 26),
        end: new Date(2023, 3, 26),
    },
    {
        title: "Clase de rumba",
        start: new Date(2023, 3, 26),
        end: new Date(2023, 3, 26),
    },
    {
        title: "Folcloriada - trae tu falda",
        start: new Date(2023, 3, 27),
        end: new Date(2023, 3, 27),
    },
    {
        title: "Show Central y Clausura",
        start: new Date(2023, 3, 28),
        end: new Date(2023, 3, 28),
    },
    {
        title: "Caminada al Alto San Miguel",
        start: new Date(2023, 3, 22),
        end: new Date(2023, 3, 22),
    },
    {
        title: "Caminada al Alto San Miguel",
        start: new Date(2023, 3, 23),
        end: new Date(2023, 3, 23),
    },
    {
        title: "Consultorio filosófico",
        start: new Date(2023, 4, 12),
        end: new Date(2023, 4, 12),
    },
    {
        title: "Cuentería en la casa de la independencia",
        start: new Date(2023, 4, 12),
        end: new Date(2023, 4, 12),
    },
    {
        title: "Noche de teatro: Botacampana-Poliescénico",
        start: new Date(2023, 4, 13),
        end: new Date(2023, 4, 13),
    },
    {
        title: "Mujeres emprendedoras",
        start: new Date(2023, 4, 18),
        end: new Date(2023, 4, 18),
    },
    {
        title: "Serenata dia de las madres",
        start: new Date(2023, 4, 12),
        end: new Date(2023, 4, 12),
    },
    {
        title: "Serenata dia de las madres",
        start: new Date(2023, 4, 19),
        end: new Date(2023, 4, 19),
    },
    {
        title: "Serenata dia de las madres",
        start: new Date(2023, 4, 26),
        end: new Date(2023, 4, 26),
    },
    {
        title: "Serenata dia de las madres",
        start: new Date(2023, 4, 27),
        end: new Date(2023, 4, 27),
    },
    {
        title: "NOCHES DE TEATRO",
        start: new Date(2023, 4, 20),
        end: new Date(2023, 4, 20),
    },
    {
        title: "DESFILE INAUGURAL FIESTAS DEL PLÁTANO",
        start: new Date(2023, 7, 27),
        end: new Date(2023, 7, 27),
    },
    {
        title: "CONVERSATORIO DE POESIA",
        start: new Date(2023, 8, 1),
        end: new Date(2023, 8, 1),
    },
    {
        title: "THE BEATSIDE CONCIERTO",
        start: new Date(2023, 7, 30),
        end: new Date(2023, 7, 30),
    },
    {
        title: "TITANES DEL ROCK",
        start: new Date(2023, 8, 1),
        end: new Date(2023, 8, 1),
    },
    {
        title: "TOMA CULTURAL EN CRA 45",
        start: new Date(2023, 7, 27),
        end: new Date(2023, 7, 28),
    },
];

function CalendarSabaneta() {
    
    const [allEvents, ] = useState(events);

    

    return (
        <div className="Calendar">
            
            <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end" style={{ height: 500, margin: "50px" }} />
        </div>
    );
}

export default CalendarSabaneta;