import "./datosStyleE.css"
import DatosE from "./DatosE"
/*
import ev1 from "../../components/assets/Eventos/barquereña.jpg"
import ev2 from "../../components/assets/Eventos/talleres.jpg"
import ev4 from "../../components/assets/Eventos/carrera.jpg"
import ev3 from "../../components/assets/Eventos/caminata.jpg"
import ev5 from "../../components/assets/Eventos/ev5.jpg"
import ev6 from "../../components/assets/Eventos/ev6.jpg"
import ev7 from "../../components/assets/Eventos/ev7.jpg"
import ev8 from "../../components/assets/Eventos/ev8.jpg"
import ev9 from "../../components/assets/Eventos/ev9.jpg"
import ev10 from "../../components/assets/Eventos/semanadanza1.jpg"
import ev11 from "../../components/assets/Eventos/semanadanza2.jpg"
import ev12 from "../../components/assets/Eventos/camin.jpg"
*/

import SliderData from "../imageSlider/SliderData"


import ev7 from "../../components/assets/Eventos/ev7.jpg"
import ev8 from "../../components/assets/Eventos/ev8.jpg"
import ev9 from "../../components/assets/Eventos/ev9.jpg"

import arreglo from "./arreglo"
function EDatosE(){
    var dato = 0;
    var eventos= arreglo();
    var enventos_fin = [];
    var lengthE = eventos.length;
    var i = -1;
    while (i < lengthE){
        if (i < 0){
            enventos_fin.push(constructorI())
            i++;
        }
        console.log(eventos.length);
        if((lengthE - dato) >= 3){
            
            enventos_fin.push(constructor(dato,eventos))
            dato += 3;
            i += 3;
        }

        if((lengthE - dato) === 2 ){
            
            enventos_fin.push(constructor2(dato,eventos))
            dato += 2;
            i += 2;
        }

        if((lengthE - dato) === 1 ){
            
            enventos_fin.push(constructor1(dato,eventos))
            dato += 1;
            i += 1;
        }

        /*
        
        if((lengthE - dato) > 1 && (lengthE - dato) < 2 ){
            
            enventos_fin.push(constructor(dato,eventos))
            dato += 3;
            i += 3;
        }
        */
        
    }

    enventos_fin.push(ender())
    
    return(enventos_fin)
    
}

export function constructorI(){
    return(
    <div className="datosE">
    <h1>Próximos Eventos</h1>
    </div>
    )
}



export function constructor(dato, eventos){
    return(
    <div className="datosE">
        <div className="DatosE">
            <DatosE
            image={eventos[dato].imagen}
            heading= {eventos[dato].heading}
            text={eventos[dato].text}
            />
            
            <DatosE
            image={eventos[dato+1].imagen}
            heading= {eventos[dato+1].heading}
            text={eventos[dato+1].text}
            />
            <DatosE
            image={eventos[dato+2].imagen}
            heading= {eventos[dato+2].heading}
            text={eventos[dato+2].text}
            />
        </div>
    </div>
    )
}

export function constructor2(dato, eventos){
    return(
    <div className="datosE">
        <div className="DatosE">
            <DatosE
            image={eventos[dato].imagen}
            heading= {eventos[dato].heading}
            text={eventos[dato].text}
            />
            
            <DatosE
            image={eventos[dato+1].imagen}
            heading= {eventos[dato+1].heading}
            text={eventos[dato+1].text}
            />
        </div>
    </div>
    )
}

export function constructor1(dato, eventos){
    return(
    <div className="datosE">
        <div className="DatosE">
            <DatosE
            image={eventos[dato].imagen}
            heading= {eventos[dato].heading}
            text={eventos[dato].text}
            />
            
        </div>
    </div>
    )
}

export function ender(){
    return(
    
        <div className="datosE">
            
            <div className="first-desIn">
                <div className="des-textE">
                    <h1>Las Fiestas del Plátano</h1>
                    <p>
                    Esta celebración nació por la importancia que tuvo el plátano hace muchos años. Sabaneta contaba con una gran cantidad de fincas plataneras, principalmente en las veredas y en el casco urbano. 
    
                    Es una actividad que se realiza cada año para mantener viva la tradición. Son una de las fiestas más importantes que se celebran en Sabaneta porque recuerdan la identidad del municipio. 
                    </p>
    
                </div>
    
                <div className="sliderE">
                    <SliderData/>
                </div>            
            </div>
    
            
            
            <div className="DatosE">
                <DatosE
                image={ev7}
                heading= "Día Clásico del Municipio"
                text="Noviembre 22"
                />
                <DatosE
                image={ev8}
                heading= "Fiestas de Palenque"
                text="Noviembre 30"
                />
                <DatosE
                image={ev9}
                heading= "Navidad en Familia y Festival de Coros"
                text="Diciembre"
                />
                
            </div>
                 
        </div>
        
        )
}

export default EDatosE
    /*
    return(
    
        
    <div className="datosE">
        <h1>Próximos Eventos</h1>
        <div className="DatosE">
            <DatosE
            image={eventos[dato].imagen}
            heading= {eventos[dato].heading}
            text={eventos[dato].text}
            />
            
            <DatosE
            image={eventos[dato+1].imagen}
            heading= {eventos[dato+1].heading}
            text={eventos[dato+1].text}
            />
            <DatosE
            image={eventos[dato+2].imagen}
            heading= {eventos[dato+2].heading}
            text={eventos[dato+2].text}
            />
            
        </div>
        <div className="DatosE">
            <DatosE
            image={ev10}
            heading= "Semana de la danza"
            text="Programación del 22 al 25 de abril"
            />
            <DatosE
            image={ev11}
            heading= "Semana de la danza"
            text="Programación del 26 al 28 de abril"
            />
            <DatosE
            image={ev12}
            heading= "Caminata al Alto de San Miguel"
            text="Abril 22 y 23. Informes en el Whastapp 300 320 37 84"
            />
            
        </div>
        <div className="DatosE">
            <DatosE
            image={ev4}
            heading= "4ta Carrera de Orientación"
            text="Abril 23 - Mayor información: 320 771 7917 "
            />
            <DatosE
            image={ev5}
            heading= "Fiestas de María Auxiliadora"
            text="Mayo 24"
            />
            <DatosE
            image={ev6}
            heading= "Fiestas de Santa Ana"
            text="Junio 18 al 26"
            />
</div>
        
        <div className="first-desIn">
                    <div className="des-textE">
                    <h1>Las Fiestas del Plátano</h1>
                        <p>
                        Esta celebración nació por la importancia que tuvo el plátano hace muchos años. Sabaneta contaba con una gran cantidad de fincas plataneras, principalmente en las veredas y en el casco urbano. 

                        Es una actividad que se realiza cada año para mantener viva la tradición. Son una de las fiestas más importantes que se celebran en Sabaneta porque recuerdan la identidad del municipio. 
                        </p>

                    </div>

                    <div className="sliderE">
                    <SliderData/>
                    </div>
                    
                    
                    
                    
                </div>

        
        
        <div className="DatosE">
            <DatosE
            image={ev7}
            heading= "Día Clásico del Municipio"
            text="Noviembre 22"
            />
            <DatosE
            image={ev8}
            heading= "Fiestas de Palenque"
            text="Noviembre 30"
            />
            <DatosE
            image={ev9}
            heading= "Navidad en Familia y Festival de Coros"
            text="Diciembre"
            />
            
        </div>
             
    </div>
    
    )
    

}*/

